import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import SimplePageTemplate from "./simple-page-template";
import {HTMLContent} from "../components/Content";
import OpenHours from "../components/Layout/OpenHours";
import ReactMarkdown from "react-markdown";
import ExternalLink from "../components/Links/ExternalLink";

const IndexPage = ({data, location}) => {
    const {frontmatter, html} = data.markdownRemark

    return (
        <Layout location={location} meta={frontmatter.meta}>
            <SimplePageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                subheading={frontmatter.subheading}
                contentTitle={frontmatter.contentTitle}
                contentBody={html}
                contentImage={frontmatter.contentImage}
                contentComponent={HTMLContent}
            />
            <section className="has-background-primary">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1" style={{paddingTop: '0'}}>
                            <div className="columns">
                                <div className="column is-6 has-text-white"
                                     style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                    <div className="section">
                                        <h3 style={{marginBottom: '3rem'}}>
                                            <ReactMarkdown source={data.company.frontmatter.name}
                                                           className="is-size-3 is-size-5-mobile has-text-weight-semibold"/>
                                        </h3>
                                        <p className="is-size-4 is-size-5-mobile">
                                            <ExternalLink classNames="has-link-contrast" href={"https://goo.gl/maps/Nk6JvmdjeqwwFPJ99"}>{data.company.frontmatter.postcode} {data.company.frontmatter.city}, {data.company.frontmatter.address}</ExternalLink>
                                        </p>
                                    </div>
                                </div>
                                <div className="column is-6">
                                    <div className="section has-background-grey-lighter" style={{opacity: 0.9}}>
                                        <OpenHours/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate {
        company: markdownRemark(frontmatter: {templateKey: {eq: "company"}}) {
            frontmatter {
                name
                city
                postcode
                address
            }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                subheading
                contentTitle
                contentImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            html
        }
    }
`
